import {makeAutoObservable, runInAction} from "mobx"
import __global from "../../__global"
import {message} from "antd";

class adminsController {
    constructor() {
        makeAutoObservable(this)
    }

    admin = {
        id: 0,
        name: '',
        password: '',
        confirmPassword: ''
    }

    rolloverOpened = false

    getUser(id) {
        __global.request('get', `admin/${id}`)
            .then(result => {
                runInAction(() => {
                    this.admin = result
                    this.admin.password = ''
                    this.admin.confirmPassword = ''
                })
            })
    }

    saveUser() {
        if(this.admin.password !== ''){
            if(this.admin.password !== this.admin.confirmPassword){
                return message.error('Пароли должны совпадать')
            }
        } else {
            if(this.admin.id === 0){
                return message.error('Необходимо ввести пароль')
            }
        }

        if (this.admin.login === '') {
            return message.error('Необходимо ввести логин')
        }

        let data = new FormData()
        let uri = 'admin'
        if(this.admin.id > 0) {
            uri += '/update'
            data.append('id', this.admin.id)
        } else {
            uri += '/create'
            data.append('login', this.admin.login)
        }

        data.append('name', this.admin.name)
        if (this.admin.password !== '') {
            data.append('password', this.admin.password)
        }

        __global.request('post', uri, data)
            .then(result => {
                this.toggleRollover()
                __global.getList()
                message.success('Успешно')
            })
    }

    setUser() {
        this.admin = {
            id: 0,
            name: '',
            password: '',
            confirmPassword: ''
        }
    }

    deleteUser(id) {
        __global.request('delete', `admins/${id}`)
            .then(result => {
                __global.getList()
                message.success('Успешно')
            })
    }

    toggleRollover() {
        this.rolloverOpened = !this.rolloverOpened
    }
}

export default new adminsController()
