import {makeAutoObservable, runInAction} from "mobx"
import __global from "../../__global"
import {message} from "antd";

class adminsController {
    constructor() {
        makeAutoObservable(this)
    }

    item = {
        id: 0,
        title: '',
        text: '',
        image: null,
        image_id: null
    }

    imageToUpload = new DataTransfer()
    imageToUploadSelected = false

    modalOpened = false

    toggleModal(id = null) {
        if (id) {
            this.getOne(id)
        }

        this.modalOpened = !this.modalOpened
        if (!this.modalOpened) {
            this.item = {
                id: 0,
                title: '',
                text: '',
                image: null,
                image_id: null
            }
        }
    }

    getOne(id) {
        __global.request('get', `news/${id}`)
            .then(result => {
                this.item = result
            })
    }

    add() {
        if (this.item.title === '') {
            return message.error('Необходимо указать название новости')
        }
        if (this.item.text === '') {
            return message.error('Необходимо указать текст новости')
        }

        let data = new FormData()
        data.append('title', this.item.title)
        data.append('text', this.item.text)
        data.append('content', this.item.content)

        if (this.imageToUploadSelected) {
            data.append('image', this.imageToUpload)
        }

        __global.request('post', 'news/add', data)
            .then(result => {
                this.imageToUpload = new DataTransfer()
                this.imageToUploadSelected = false
                this.toggleModal()
                __global.getList()
            })
    }

    edit() {
        if (this.item.title === '') {
            return message.error('Необходимо указать название новости')
        }
        if (this.item.text === '') {
            return message.error('Необходимо указать текст новости')
        }

        let data = new FormData()
        data.append('id', this.item.id)
        data.append('title', this.item.title)
        data.append('text', this.item.text)
        data.append('content', this.item.content)

        if (this.imageToUploadSelected) {
            data.append('image', this.imageToUpload)
        }

        __global.request('post', 'news/edit', data)
            .then(result => {
                this.imageToUpload = new DataTransfer()
                this.imageToUploadSelected = false
                this.toggleModal()
                __global.getList()
            })
    }

    delete(id) {
        __global.request('delete', `news/${id}`)
            .then(result => {
                __global.getList()
            })
    }
}

export default new adminsController()
