import {makeAutoObservable, runInAction} from "mobx"
import __global from "../../__global"
import __auth from "../auth/__auth";
import {message} from "antd";
import fileDownload from "js-file-download";

class usersController {
    constructor() {
        makeAutoObservable(this)
    }

    rolloverOpened = false
    blockReason = ''

    getUser(id) {
        __global.request('get', `user/${id}`)
            .then(result => {
                runInAction(() => {
                    __auth.user = result
                    __auth.user.password = ''
                    __auth.user.confirmPassword = ''
                })
            })
    }

    saveUser() {
        let check = __auth.checkFields('update')
        if(check !== true) return

        let data = new FormData()
        let uri = 'user'
        if(__auth.user.id > 0) {
            uri += '/update'
            data.append('id', __auth.user.id)
        } else {
            uri += '/create'
        }

        data.append('name', __auth.user.name)
        data.append('phone', __auth.user.phone)
        data.append('email', __auth.user.email)

        if (__auth.user.password !== '') {
            data.append('password', __auth.user.password)
        }

        __global.request('post', uri, data)
            .then(result => {
                this.toggleRollover()
                __global.getList()
                message.success('Успешно')
            })
    }

    blockUser(status, id) {
        let data = new FormData()

        data.append('id', id)
        data.append('status', status)
        if(status === 1) {
            if(this.blockReason === '') {
                return message.error('Необходимо указать причину блокировки')
            }

            data.append('reason', this.blockReason)
        }

        __global.request('post', 'user/block', data)
            .then(result => {
                __global.getList()
                message.success('Успешно')
            })
    }

    toggleRollover() {
        __auth.setUser()
        this.rolloverOpened = !this.rolloverOpened
    }

    download() {
        let url = __global.getURLQuery('users/download')

        __global.request('get', url, {}, true, {
            responseType: 'blob'
        })
            .then(result => {
                let data = result.data
                let fileName = '1.xlsx'

                const contentDisposition = result.headers['content-disposition'];
                if (contentDisposition) {
                    const utf8ValueMatch = contentDisposition.match(/filename\*=utf-8''(.+)/);
                    if (utf8ValueMatch && utf8ValueMatch[1]) {
                        fileName = decodeURIComponent(utf8ValueMatch[1]);
                    }
                }

                fileDownload(data, fileName)
            })
    }
}

export default new usersController()
